export default async function (question_id, token) {
  return await fetch(
    `${process.env.VUE_APP_API_URL}/voter/questions/${question_id}`,
    {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}
