import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/browser-not-supported',
    name: 'BrowserNotSupported',
    component: () => import('@/views/BrowserNotSupported.vue'),
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('@/views/Unauthorized.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/Logout.vue'),
  },
  {
    path: '/login/:access_token',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    props: true,
    meta: {
      guest: true,
    },
  },
  {
    path: '/tfa/:access_token',
    name: 'TwoFactorAuthentication',
    component: () => import('@/views/TwoFactorAuthentication.vue'),
    props: true,
    meta: {
      guest: true,
    },
  },
  {
    path: '/questions',
    name: 'Questions',
    component: () => import('@/views/Questions.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/question/:id',
    name: 'Question',
    component: () => import('@/views/Question.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/communication',
    name: 'Communication',
    component: () => import('@/views/Communication.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('@/views/Documents.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import('@/views/Agenda.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/watch-stream',
    name: 'WatchStream',
    component: () => import('@/views/WatchStream.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/end',
    name: 'End',
    component: () => import('@/views/End.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // Returning the savedPosition will result in a native-like behavior
      // when navigating with back/forward buttons:
      return savedPosition
    } else {
      // This will simply make the page scroll to top for all route navigations.
      return { x: 0, y: 0 }
    }
  },
})

export default router
