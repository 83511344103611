<template>
  <div>
    <router-view v-on:logout="logout"></router-view>
    <v-btn
      v-if="
        this.$mystore.isAuthenticated() &&
        this.$mystore.state.event !== null &&
        this.$mystore.state.meetingSession !== null
      "
      elevation="2"
      fab
      right
      fixed
      bottom
      @click="muteUnmute"
    >
      <v-icon v-if="microphoneMuted">mdi-microphone-off</v-icon>
      <v-icon v-else>mdi-microphone</v-icon>
    </v-btn>
  </div>
</template>

<script>
// import Pusher from 'pusher-js'
import Pusher from 'pusher-js/with-encryption'
import get_event from '@/api/get_event.js'
import obtain_voter from '@/api/obtain_voter.js'
import catch_errors from '@/api/catch_errors.js'
import handle_errors from '@/api/handle_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'
import beforeunload from '@/helpers/beforeunload.js'
import getQuestion from '@/api/getQuestion.js'
import logout from '@/api/logout.js'
import handle_logged_out from '@/helpers/handle_logged_out.js'
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js'
import has_voted from '@/helpers/has_voted.js'

let amazonChimeLogLevel = LogLevel.OFF

if (process.env.NODE_ENV !== 'production') {
  Pusher.logToConsole = true
  amazonChimeLogLevel = LogLevel.INFO
}

// Set Pusher to use credentials. Because session is stored in a cookie.
// See this GitHub issue for more info
// https://github.com/pusher/pusher-js/issues/471#issuecomment-659107992
Pusher.Runtime.createXHR = function () {
  var xhr = new XMLHttpRequest()
  xhr.withCredentials = true
  return xhr
}

async function get_active_question(token) {
  return await fetch(`${process.env.VUE_APP_API_URL}/voter/questions/active`, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export default {
  watch: {
    '$mystore.state.loggedIn': function (newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.fetchData()
      }
    },
  },

  computed: {
    microphoneMuted: function () {
      if (this.$mystore.state.meetingSession === null) {
        return true
      }

      return this.$mystore.state.meetingSession.audioVideo.realtimeIsLocalAudioMuted()
    },
  },

  created() {
    if (this.$mystore.isAuthenticated()) {
      this.fetchData()
    }
  },

  methods: {
    async stopMeetingSession() {
      if (this.$mystore.state.meetingSession === null) {
        return
      }

      this.$mystore.state.meetingSession.audioVideo.removeObserver(
        this.meetingObserver()
      )

      await this.$mystore.state.meetingSession.audioVideo.stop()
    },

    logout(routeName = 'Logout') {
      logout(localStorage.getItem('token'))
        .then(handle_token_refresh)
        .then(handle_errors)
        .then(() => {
          this.$mystore.setLoggedIn(false)

          beforeunload.disable()

          // Disconnect from pusher.
          this.$mystore.state.pusherInstance.disconnect()

          this.stopMeetingSession()

          localStorage.removeItem('token')

          this.$router.push({ name: routeName })
        })
        .catch(response => {
          catch_errors(response, this.$router, this.$mystore)
        })
    },

    fetchData() {
      this.fetchVoter()
    },

    fetchVoter() {
      obtain_voter(localStorage.getItem('token'))
        .then(handle_token_refresh)
        .then(handle_errors)
        .then(data => {
          this.$mystore.setVoter(data.data)

          this.fetchEvent()
        })
        .catch(response => {
          catch_errors(response, this.$router, this.$mystore)
        })
    },

    fetchEvent() {
      get_event(localStorage.getItem('token'))
        .then(handle_token_refresh)
        .then(handle_errors)
        .then(data => {
          this.$mystore.setEvent(data.data.event)

          this.$i18n.locale = this.$mystore.state.event.language

          if (this.$mystore.state.event.isFinished()) {
            this.$router.push({ name: 'End' })
            return
          }

          this.initializePusher()

          if (
            this.$mystore.state.event.voter_ui_type === 'advanced' &&
            this.$mystore.state.event.enable_audio_video == true
          ) {
            this.startMeetingSession(
              data.data.meetingResponse,
              data.data.attendeeResponse
            )
          }
        })
        .catch(response => {
          catch_errors(response, this.$router, this.$mystore)
        })
    },

    async startMeetingSession(meetingResponse, attendeeResponse) {
      const logger = new ConsoleLogger('MyLogger', amazonChimeLogLevel)
      const deviceController = new DefaultDeviceController(logger)

      const configuration = new MeetingSessionConfiguration(
        { Meeting: meetingResponse.Meeting },
        { Attendee: attendeeResponse.Attendee }
      )

      this.$mystore.setMeetingSession(
        new DefaultMeetingSession(configuration, logger, deviceController)
      )

      const audioInputDevices = await this.$mystore.state.meetingSession.audioVideo.listAudioInputDevices()
      await this.$mystore.state.meetingSession.audioVideo.chooseAudioInputDevice(
        audioInputDevices[0]
      )

      const audioOutputDevices = await this.$mystore.state.meetingSession.audioVideo.listAudioOutputDevices()
      await this.$mystore.state.meetingSession.audioVideo.chooseAudioOutputDevice(
        audioOutputDevices[0]
      )

      this.$mystore.state.meetingSession.audioVideo.bindAudioElement(
        document.getElementById('amazon-chime-audio')
      )

      this.$mystore.state.meetingSession.audioVideo.addObserver(
        this.meetingObserver()
      )

      // Start listening audio.
      this.$mystore.state.meetingSession.audioVideo.start()

      // Mute by default.
      this.$mystore.state.meetingSession.audioVideo.realtimeMuteLocalAudio()
    },

    meetingObserver() {
      return {
        audioVideoDidStop: () => {
          this.stopMeetingSession()
        },

        videoTileWasRemoved: tileId => {
          this.$mystore.removeStream(tileId)
        },

        videoTileDidUpdate: tileState => {
          // Ignore a tile without attendee ID and videos.
          if (!tileState.boundAttendeeId || !tileState.isContent) {
            return
          }

          // Prevent duplicates.
          if (this.$mystore.state.streams.indexOf(tileState.tileId) !== -1) {
            return
          }

          this.$mystore.addStream(tileState.tileId)
        },
      }
    },

    muteUnmute() {
      const muted = this.$mystore.state.meetingSession.audioVideo.realtimeIsLocalAudioMuted()

      if (muted) {
        this.$mystore.state.meetingSession.audioVideo.realtimeUnmuteLocalAudio()
      } else {
        this.$mystore.state.meetingSession.audioVideo.realtimeMuteLocalAudio()
      }
    },

    // This is called only when the user logs in. Cool!
    initializePusher() {
      this.$mystore.setPusherInstance(
        new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
          cluster: 'eu',
          authEndpoint: `${process.env.VUE_APP_API_URL}/voter/pusher/auth-private-channel`,
          auth: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        })
      )

      // When the voter goes offline for some reason or if Pusher can't connect for some reason.
      this.$mystore.state.pusherInstance.connection.bind('unavailable', () => {
        this.logout()
      })

      // When Pusher has connected, fetch active question.
      this.$mystore.state.pusherInstance.connection.bind('connected', () => {
        this.fetchActiveQuestion()
      })

      this.$mystore.state.pusherInstance.connection.bind('failed', () => {
        this.logout('BrowserNotSupported')
      })

      // Used for channel presence to avoid 100 members limit.
      let pusherChannel_presence = this.$mystore.state.pusherInstance.subscribe(
        `private-presence-event_${this.$mystore.state.event.id}-voter_${this.$mystore.state.voter.session_id}`
      )

      pusherChannel_presence.bind('logout', report_token => {
        this.stopMeetingSession()

        handle_logged_out(report_token, this.$mystore, this.$router)
      })

      this.$mystore.setPusherChannelQuestions(
        this.$mystore.state.pusherInstance.subscribe(
          `private-encrypted-event_${this.$mystore.state.event.id}_questions`
        )
      )

      this.$mystore.state.pusherChannel_questions.bind('start', question_id => {
        getQuestion(
          question_id,
          data => {
            this.$mystore.setActiveQuestion(data.data.question)

            this.$router.push({ name: 'Question', params: { id: question_id } })
          },
          response => catch_errors(response, this.$router, this.$mystore)
        )
      })

      this.$mystore.state.pusherChannel_questions.bind('stop', () => {
        // The user can navigate to other pages.
        this.$mystore.removeActiveQuestion()
      })

      let pusherChannel_events = this.$mystore.state.pusherInstance.subscribe(
        `private-encrypted-event_${this.$mystore.state.event.id}_events`
      )

      pusherChannel_events.bind('stop', data => {
        // The user can navigate to other pages.
        this.$mystore.removeActiveQuestion()

        if (this.$mystore.state.event !== null) {
          this.$mystore.state.event.status = data.status
          this.$mystore.state.event.end = data.end
        }

        this.$router.push({ name: 'End' })

        this.$mystore.setLoggedIn(false)

        beforeunload.disable()

        // Disconnect from pusher.
        this.$mystore.state.pusherInstance.disconnect()

        // Prevents unauthorized when using a new token.
        localStorage.removeItem('token')
      })
    },

    fetchActiveQuestion() {
      get_active_question(localStorage.getItem('token'))
        .then(handle_token_refresh)
        .then(handle_errors)
        .then(data => {
          if (data.data.active === false) {
            return
          }

          this.$mystore.setActiveQuestion(data.data.question)

          if (
            this.$route.params.id != data.data.question.id &&
            has_voted(this.$mystore.state.activeQuestion.voted) === false
          ) {
            this.$router.push({
              name: 'Question',
              params: { id: data.data.question.id },
            })
          }
        })
        .catch(response => {
          catch_errors(response, this.$router, this.$mystore)
        })
    },
  },
}
</script>

<style scoped>
::v-deep .break-all {
  word-break: break-all;
  white-space: normal;
}
</style>
