export default {
  components: {
    layouts: {
      main: {
        logout: 'Logout',
        /* logout: 'Logout - CERRAR SESIÓN - SE DÉCONNECTER', */
        questions: 'Elections',
        /* questions: 'Elections - Elecciones - Élections', */
        communication: 'Ask a question',
        documents: 'Documents',
        agenda: 'Agenda',
        anonymous: 'Voting is anonymous',
        transparent: 'Voting is public',
        /* anonymous:
          'EN: Voting is anonymous \n\r ES: Votar es anónimo\n\r FR: Le vote est anonyme',
        transparent:
          'EN: Voting is public \n\r ES: Votar es publico \n\r FR: Le vote est public', */
        watchStream: 'Watch stream',
      },
    },
    switchDevicePrompt: {
      heading: ' Log in here?',
      /* heading:
        ' EN: Log in here? \n\r ES: ¿Entre aquí? \n\r FR: Connectez-vous ici?', */
      text:
        'You are already logged in to another browser. Are you sure you want to log out of another browser and access through this one?',
      /* text:
        " EN: You are already logged in to another browser. Are you sure you want to log out of another browser and access through this one? \n\r ES: Ya ha iniciado sesión en otro navegador. ¿Estás seguro de que quieres cerrar sesión en otro navegador y acceder a través de este? \n\r FR: Vous êtes déjà connecté sur un autre navigateur. Voulez-vous vraiment vous déconnecter d'un autre navigateur et accéder à celui-ci ?", */
      yes: 'Yes',
      no: 'No',
      doesNotWantToLogin: 'You are logged in to the app on another browser.',
    },
  },
  views: {
    agenda: {
      title: 'Agenda',
    },
    watchStream: {
      title: 'Live Stream',
      noActiveStream: 'There is no active stream at the moment.',
    },
    communication: {
      title: 'Questions',
      allQuestions: 'All Questions',
      myQuestions: 'My Questions',
      askQuestion: 'Ask a Question',
      question: 'Pitanje',
      validation: {
        required: 'A message is required.',
        max_length: 'Message cannot be longer than 191 characters.',
      },
      close: 'Close',
      save: 'Save',
    },
    documents: {
      title: 'Documents',
    },
    home: {
      title: 'Start Page',
      /* title: 'Start Page - Página de inicio - Page de démarrage', */
    },
    end: {
      title: 'Event closed',
      message: 'Thank you for participating. This event is no longer active.',
      /* message:
        " EN: Thank you for participating. This event is no longer active. \n\r ES: Gracias por participar. Este evento ya no está activo. \n\r FR: Merci d'avoir participé. Cet événement n'est plus actif.", */
    },
    question: {
      title: 'Election',
      resultsNotVisibleDuringEventActive:
        'Results are not visible during the election.',
      /* resultsNotVisibleDuringEventActive:
        " EN: Results are not visible during the election. \n\r ES: Los resultados no son visibles durante la elección. \n\r FR: Les résultats ne sont pas visibles pendant l'élection.", */
      thankYouForVoting: 'Your vote has been recorded.',
      /* thankYouForVoting:
        'EN: Your vote has been recorded. - ES: Tu voto ha sido guardado. - FR: Votre vote a été enregistré.', */
      theseAreEventResults: 'These are the election results.',
      /* theseAreEventResults:
        ' EN: These are the election results. \n\r ES: Estos son los resultados de las elecciones. \n\r FR: Ce sont les résultats des élections.', */
      noResults: 'No results. No one voted.',
      refreshResults: 'Refresh results',
      abstention: 'Abstain',
      /* abstention: 'Abstain - Abstenerse - Abstention', */
      send: 'Send',
      /* send: 'Send - ENVIAR - ENVOYER', */
      validation: {
        min: 'You must choose at least {value} answers.',
        max:
          "You may choose up to {value} answers. - Puede elegir hasta {value} respuestas. - Vous pouvez choisir jusqu'à {value} réponses.",
        required: 'You must choose at least one answer.',
      },
      abstentioned: 'Abstain',
      /* abstentioned: 'Abstain - Abstenerse - Abstention', */
      yourVote: 'Your vote',
    },
    questions: {
      title: 'Elections',
      /* title: 'Elections - Elecciones - Élections', */
      noActiveQuestions: 'There are currently no active elections.',
      /* noActiveQuestions:
        " EN: There are currently no active elections. \n\r ES: Actualmente no hay elecciones activas. \n\r FR: Il n'y a actuellement aucune élection active.", */
    },
    tfa: {
      title: 'Tfa',
      hello: 'Greetings,',
      pleasePrepareMobileDevice:
        'please ready your mobile device for voting authorization.',
      pinToMobile:
        'The authorization PIN will be sent via SMS to your mobile device. The signed number ends with "{value}".',
      pinValid:
        "The authorization PIN is active for 10 hours after you've received it.",
      helpContact:
        'For any additional questions, please refer to the election administrator at {value}.',
      sendPin: 'Send PIN',
      enterPin: 'Enter PIN',
      pinNotReceivedOrExpired:
        "You haven't received your PIN or it has expired?",
      pinRequestLimit: 'PIN was requested too many times.',
      talkToAdministrator: 'Contact the administrator at address',
      validation: {
        required: 'PIN required.',
        length: 'PIN must contain four numbers.',
        number: 'PIN must be a number.',
      },
      pinNotValidOrExpired: 'PIN is invalid or expired.',
    },
    login: {
      unauthorized:
        'The requested page is not active. Your vote has already been recorded or the vote period has ended.',
    },
    unauthorized: {
      title: 'FairVotes.LIVE',
      message: 'You are unauthorized to access the application.',
    },
    logout: {
      title: 'FairVotes.LIVE',
      message:
        "You have signed out. To re-enter FairVotes.Live, click on the 'Go to Event' link in the email you received at your email address.",
      /* message:
        " EN: You have signed out. To re-enter FairVotes.Live, click on the 'Go to Event' link in the email you received at your email address.\n\r ES: Has cerrado sesión. Para volver a ingresar a FairVotes.Live, haga clic en el enlace 'Ir al evento' en el correo electrónico que recibió en su dirección de correo electrónico.\n\rFR: Vous vous êtes déconnecté. Pour entrer à nouveau dans FairVotes.Live, cliquez sur le lien 'Aller à l'événement' dans l'e-mail que vous avez reçu à votre adresse e-mail.", */
      loggedOut: {
        heading: 'Logged in from another device?',
        /* heading:
          ' EN: Logged in from another device? \n\r ES: ¿Iniciaste sesión desde otro dispositivo? \n\r FR: Connecté depuis un autre appareil ?', */
        text:
          'You are logged out of this device because you have accessed the system through another device. Was that you?',
        /* text:
          " EN: You are logged out of this device because you have accessed the system through another device. Was that you? \n\r ES: Se desconectó de este dispositivo porque accedió al sistema a través de otro dispositivo. ¿Eras tú? \n\r FR: Vous êtes déconnecté de cet appareil car vous avez accédé au système via un autre appareil. C'était toi ?", */
        yes: 'Yes',
        no: 'No',
        alert: 'Your report was sent successfully.',
        /* alert:
          ' EN: Your report was sent successfully. \n\r ES: Su informe fue enviado con éxito. \n\r FR: Votre rapport a été envoyé avec succès.', */
      },
      alert:
        'An error occurred while sending the request. Please try again or report this issue via email.',
    },
    browser_not_supported: {
      title: 'FairVotes.LIVE',
      message: 'Your Internet browser is not supported.',
    },
  },
}
