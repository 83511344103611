export default {
  components: {
    layouts: {
      main: {
        logout: 'Preveri',
        questions: 'Glasovanje',
        communication: 'Vprašanja',
        documents: 'Dokumenti',
        agenda: 'Agenda',
        anonymous: 'Glasovanje je anonimno.',
        transparent: 'Glasovanje ni anonimno.',
      },
    },
  },
  views: {
    agenda: {
      title: 'Agenda',
    },
    communication: {
      title: 'Vprašanja',
      allQuestions: 'Vsa vprašanja',
      myQuestions: 'Moja vprašanja',
      askQuestion: 'Postavi vprašanje',
      question: 'Pitanje',
      validation: {
        required: 'Sporočilo je treba vnesti.',
        max_length: 'Sporočilo ne sme biti daljše od 191 znakov.',
      },
      close: 'Zapri',
      save: 'Shrani',
    },
    documents: {
      title: 'Dokumenti',
    },
    home: {
      title: 'Začetna stran',
    },
    end: {
      title: 'Event dokončan',
      message: 'Hvala za sodelovanje. Ta dogodek ni več aktiven.',
    },
    question: {
      title: 'Glasovanje',
      resultsNotVisibleDuringEventActive: 'Rezultati med volitvami niso vidni.',
      thankYouForVoting: 'Vaš glas je zabeležen.',
      theseAreEventResults: 'To so rezultati glasovanja.',
      noResults: 'Ni rezultatov. Nihče ni glasoval..',
      refreshResults: 'Osveži rezultate',
      abstention: 'Vzdržan/Vzdržana sem',
      send: 'Pošlji',
      validation: {
        min: 'Izbrati morate najmanj {value} odgovora.',
        max: 'Izbrati morate največ {value} odgovora.',
        required: 'Izbrati morate vsaj en odgovor.',
      },
      abstentioned: 'Vzdržani',
      yourVote: 'Vaš glas',
    },
    questions: {
      title: 'Glasovanje',
      noActiveQuestions: 'Pozdravljeni, trenutno ni aktivnih glasovnic.',
    },
    tfa: {
      title: 'Tfa',
      hello: 'Pozdravljeni,',
      pleasePrepareMobileDevice:
        'pripravite svoj mobilni telefon, da boste lahko glasovali.',
      pinToMobile:
        'Pooblastitveno kodo PIN pošljete s sporočilom SMS na vaš mobilni telefon. Poročeno število se konča z "{value}".',
      pinValid: 'Pooblastitvena koda PIN je aktivna 10 uri od prejema.',
      helpContact:
        'Za dodatna vprašanja se obrnite na skrbnika glasovanja na {value}.',
      sendPin: 'Pošlji PIN',
      enterPin: 'Vnesite prejeto kodo PIN',
      pinNotReceivedOrExpired: 'Niste dobili PIN ali potekli?',
      pinRequestLimit: 'Pin je zahteval prevečkrat.',
      talkToAdministrator: 'Obrnite se na organizatorja',
      validation: {
        required: 'Potreben je PIN..',
        length: 'Koda PIN mora vsebovati štiri številke.',
        number: 'Koda PIN mora biti številka.',
      },
      pinNotValidOrExpired: 'Koda PIN je neveljavna ali potekla.',
    },
    unauthorized: {
      title: 'FairVotes LIVE',
      message: 'Pooblaščeni ste za dostop do aplikacije.',
    },
    logout: {
      title: 'FairVotes LIVE',
      message:
        'Odjavili ste se. Če želite znova vstopiti v FairVotes.Live, kliknite povezavo »Pojdi na dogodek« v e-poštnem sporočilu, ki ste ga prejeli na svoj e-poštni naslov. ',
    },
    browser_not_supported: {
      title: 'FairVotes.LIVE',
      message: 'Vaš internetni brskalnik ni podprt.',
    },
  },
}
