export default async function (token) {
  return await fetch(`${process.env.VUE_APP_API_URL}/voter/logout`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
