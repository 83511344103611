class Document {
  id = null

  name = ''

  original_name = ''

  event_id = null

  created_at = null

  updated_at = null

  link = ''

  // Documents are private by default (once uploaded).
  private = true

  constructor(options = {}) {
    Object.assign(this, options)
  }

  // let document = (new Document).fill({ name: "whatever" });
  fill(options) {
    return Object.assign(this, options)
  }

  getIcon() {
    switch (this.name.split('.').pop()) {
      case 'pdf':
        return 'mdi-file-pdf'

      case 'xlsx':
        return 'mdi-file-excel'

      case 'xls':
        return 'mdi-file-excel'

      case 'docx':
        return 'mdi-file-word'

      case 'doc':
        return 'mdi-file-word'

      case 'pptx':
        return 'mdi-file-powerpoint'

      case 'ppt':
        return 'mdi-file-powerpoint'

      case 'png':
        return 'mdi-file-image'

      case 'jpg':
        return 'mdi-file-image'

      case 'jpeg':
        return 'mdi-file-image'

      default:
        return 'mdi-file'
    }
  }

  getIconClass() {
    switch (this.getIcon()) {
      case 'mdi-file-pdf':
        return 'red white--text'

      case 'mdi-file-excel':
        return 'green white--text'

      case 'mdi-file-word':
        return 'blue white--text'

      case 'mdi-file-image':
        return 'orange white--text'

      default:
        return 'grey white--text'
    }
  }
}

export default Document
