import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify.js'
import i18n from '@/plugins/i18n.js'
import router from '@/plugins/router.js'
import store from '@/plugins/store.js'
import beforeunload from '@/helpers/beforeunload.js'
import has_voted from '@/helpers/has_voted.js'

Vue.config.productionTip = false

if (localStorage.getItem('token') !== null) {
  store.setLoggedIn(true)

  beforeunload.enable()
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.isAuthenticated()) {
      if (
        store.state.activeQuestion !== null &&
        has_voted(store.state.activeQuestion.voted) === false
      ) {
        if (to.params.id !== store.state.activeQuestion.id) {
          next({
            name: 'Question',
            params: { id: store.state.activeQuestion.id },
          }) // Redirect to active Question.
        } else {
          next() // Good.
        }
      } else {
        next() // Good.
      }
    } else {
      next({ name: 'Unauthorized' }) // Redirect to Unauthorized.
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (!store.isAuthenticated()) {
      next() // Good.
    } else {
      if (to.name === 'Login') {
        // This handles login with different access_token when already logged in.
        localStorage.removeItem('token')

        // Reload the page for the changes to take effect.
        location.reload()
      } else {
        next({ name: 'Home' }) // Redirect to Home.
      }
    }
  } else {
    // Used for Unauthorized view.
    next()
  }
})

new Vue({
  data: {
    sharedState: store.state,
  },
  vuetify,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
