import beforeunload from '@/helpers/beforeunload.js'

export default function (report_token, store, router) {
  store.setReportToken(report_token)
  store.setLoggedIn(false)

  // Disconnect from pusher.
  if (store.state.pusherInstance !== null) {
    store.state.pusherInstance.disconnect()
  }

  beforeunload.disable()

  localStorage.removeItem('token')

  router.push({ name: 'Logout' })
}
