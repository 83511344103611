class Question {
  id = null

  question = ''

  abstention = false

  randomize_options = false

  event_id = null

  created_at = null

  updated_at = null

  answers = []

  min_choices = 1

  max_choices = 1

  multiple_choices_enabled = false

  started_at = null

  stopped_at = null

  // This field is generated on the API endpoint, not in the database table or model.
  voted = false

  // This field is generated on the API endpoint, not in the database table or model.
  statistics = []

  constructor(options = {}) {
    Object.assign(this, options)
  }

  // let question = (new Question).fill({ question: "whatever" })
  fill(options) {
    return Object.assign(this, options)
  }

  isActive() {
    return this.started_at !== null && this.stopped_at === null
  }

  isFinished() {
    return this.started_at !== null && this.stopped_at !== null
  }

  isAnonymous() {
    return this.anonymous
  }
}

export default Question
