import Vue from 'vue'
import Document from '@/models/Document.js'
import Message from '@/models/Message.js'
import Event from '@/models/Event.js'
import Question from '@/models/Question.js'

const store = {
  state: {
    voter: null,
    agenda: '',
    documents: [],
    messages: [],
    event: null,
    questions: [],
    initial_check_for_active_question: false,
    activeQuestion: null,
    pusherInstance: null,
    // Used for detecting when a question has stopped.
    pusherChannel_questions: null,
    loggedIn: false,
    report_token: null,
    meetingSession: null,
    streams: [],
    snackbar: false,
    snackbarText: '',
  },

  notifyUser(text) {
    this.state.snackbarText = text
    this.state.snackbar = true
  },

  closeUserNotification() {
    this.state.snackbar = false
    this.state.snackbarText = ''
  },

  setReportToken(value) {
    this.state.report_token = value
  },

  removeReportToken() {
    this.state.report_token = null
  },

  isAuthenticated() {
    return !!this.state.loggedIn
  },

  setLoggedIn(value) {
    this.state.loggedIn = value
  },

  setMeetingSession(meetingSession) {
    this.state.meetingSession = meetingSession
  },

  addStream(id) {
    this.state.streams.push(id)
  },

  removeStream(id) {
    this.state.streams.splice(this.state.streams.indexOf(id), 1)
  },

  setPusherInstance(pusher) {
    this.state.pusherInstance = pusher
  },

  setPusherChannelQuestions(channel) {
    this.state.pusherChannel_questions = channel
  },

  setActiveQuestion(question) {
    this.state.activeQuestion = new Question(question)
  },

  removeActiveQuestion() {
    this.state.activeQuestion = null
  },

  removeVoter() {
    this.state.voter = null
  },

  setVoter(voter) {
    this.state.voter = voter
  },

  completeInitialCheckForActiveQuestion() {
    this.state.initial_check_for_active_question = true
  },

  setAgenda(agenda) {
    this.state.agenda = agenda
  },

  setEvent(event) {
    this.state.event = new Event(event)
  },

  setDocuments(documents) {
    this.state.documents = documents
  },

  addDocument(document) {
    this.state.documents.push(new Document(document))
  },

  removeDocument(document_id) {
    for (let i = 0; i < this.state.documents.length; i++) {
      if (this.state.documents[i].id === document_id) {
        this.state.documents.splice(i, 1)
      }
    }
  },

  updateDocument(document) {
    for (let i = 0; i < this.state.documents.length; i++) {
      if (this.state.documents[i].id === document.id) {
        this.state.documents.splice(i, 1, new Document(document))
      }
    }
  },

  setMessages(messages) {
    this.state.messages = messages
  },

  addMessage(message) {
    this.state.messages.push(new Message(message))
  },

  removeMessage(message_id) {
    for (let i = 0; i < this.state.messages.length; i++) {
      if (this.state.messages[i].id === message_id) {
        this.state.messages.splice(i, 1)
      }
    }
  },

  markMessageSeen(message_id) {
    for (let i = 0; i < this.state.messages.length; i++) {
      if (this.state.messages[i].id === message_id) {
        this.state.messages[i].seen = true
      }
    }
  },

  setQuestions(questions) {
    this.state.questions = questions
  },
}

store.install = function () {
  Object.defineProperty(Vue.prototype, '$mystore', {
    get() {
      return store
    },
  })
}

Vue.use(store)

export default store
