class Event {
  id = null

  name = ''

  client = {
    name: '',
  }

  logo = {
    name: '',
    original_name: '',
    link: '',
  }

  live_questions_visibility = 'organizer_and_voters' // organizer_only

  enable_live_questions = true

  created_at = null

  updated_at = null

  results_in_progress = false

  sms_authentication = false

  start = null

  end = null

  warmup_message = ''

  language = 'hr'

  anonymous = true

  constructor(options = {}) {
    Object.assign(this, options)
  }

  // let event = (new Event).fill({ event: "whatever" })
  fill(options) {
    return Object.assign(this, options)
  }

  isFinished() {
    return this.start !== null && this.end !== null
  }
}

export default Event
