export default {
  components: {
    layouts: {
      main: {
        logout: 'Odjava',
        questions: 'Izbori',
        communication: 'Postavi pitanje',
        documents: 'Dokumenti',
        agenda: 'Agenda',
        anonymous: 'Glasovanje je anonimno.',
        transparent: 'Glasovanje nije anonimno.',
        watchStream: 'Gledajte prijenos',
      },
    },
    switchDevicePrompt: {
      heading: 'Ulogirajte se ovdje?',
      text:
        'Već ste ulogirani na drugom pregledniku. Jeste li sigurni da se želite odjaviti s drugog preglednika i pristupiti putem ovog?',
      yes: 'Da',
      no: 'Ne',
      doesNotWantToLogin: 'Ulogirani ste u aplikaciju na drugom browseru.',
    },
  },
  views: {
    agenda: {
      title: 'Agenda',
    },
    watchStream: {
      title: 'Prijenos uživo',
      noActiveStream: 'Trenutno nema aktivnog prijenosa.',
    },
    communication: {
      title: 'Pitanja',
      allQuestions: 'Sva pitanja',
      myQuestions: 'Moja pitanja',
      askQuestion: 'Postavi pitanje',
      question: 'Pitanje',
      validation: {
        required: 'Potrebno je upisati poruku.',
        max_length: 'Poruka ne može biti duža od 191 znakova.',
      },
      close: 'Zatvori',
      save: 'Spremi',
    },
    documents: {
      title: 'Dokumenti',
    },
    home: {
      title: 'Početna stranica',
    },
    end: {
      title: 'Event završen',
      message: 'Hvala na sudjelovanju. Ovaj event više nije aktivan.',
    },
    question: {
      title: 'Izbor',
      resultsNotVisibleDuringEventActive:
        'Rezultati nisu vidljivi za vrijeme trajanja izbora.',
      thankYouForVoting: 'Vaš glas je zabilježen.',
      theseAreEventResults: 'Ovo su rezultati glasovanja.',
      noResults: 'Nema rezultata. Nitko nije glasovao.',
      refreshResults: 'Osvježi rezultate',
      abstention: 'Suzdržan/Suzdržana sam',
      send: 'Pošalji',
      validation: {
        min: 'Morate odabrati minimalno {value} odgovora.',
        max: 'Možete odabrati maksimalno {value} odgovora.',
        required: 'Morate odabrati barem jedan odgovor.',
      },
      abstentioned: 'Suzdržani',
      yourVote: 'Vaš glas',
    },
    questions: {
      title: 'Izbori',
      noActiveQuestions:
        'Poštovani, trenutno još uvijek nema aktivnih glasovanja.',
    },
    tfa: {
      title: 'Tfa',
      hello: 'Poštovani,',
      pleasePrepareMobileDevice:
        'molimo pripremite svoj mobitel kako biste se autorizirali za glasovanje.',
      pinToMobile:
        'Autorizacijski PIN će biti poslan SMS porukom na vaš mobitel. Prijavljeni broj završava sa "{value}".',
      pinValid: 'Autorizacijski PIN aktivan je 10 sati od zaprimanja.',
      helpContact:
        'Za dodatna pitanja molimo obratite se administratoru glasovanja na {value}.',
      sendPin: 'Pošalji PIN',
      enterPin: 'Upišite dobiveni PIN',
      pinNotReceivedOrExpired: 'Niste dobili PIN ili vam je istekao?',
      pinRequestLimit: 'Pin je zatražen previše puta.',
      talkToAdministrator: 'Javite se organizatoru na adresu',
      validation: {
        required: 'Obavezno upisati PIN.',
        length: 'PIN mora sadržavati četiri broja.',
        number: 'PIN mora biti broj.',
      },
      pinNotValidOrExpired: 'Uneseni PIN nije ispravan ili je istekao.',
    },
    login: {
      unauthorized:
        'Ova stranica više nije aktivna. Vaš glas je već zabilježen ili je period glasovanja završio.',
    },
    unauthorized: {
      title: 'FairVotes.LIVE',
      message: 'Niste autorizirani da pristupite aplikaciji.',
    },
    logout: {
      title: 'FairVotes.LIVE',
      message:
        'Odjavili ste se. Za ponovni ulazak u sustav FairVotes.Live, kliknite na poveznicu “Idi na event” koja se nalazi u mailu koji ste primili na Vašu email adresu.',
      loggedOut: {
        heading: 'Prijavili ste se na drugom uređaju?',
        text:
          'Izlogirani ste s ovog uređaja jer ste pristupili sustavu preko drugog uređaja. Jeste li to bili vi?',
        yes: 'Da',
        no: 'Ne',
        alert: 'Vaša prijava je uspješno poslana.',
      },
      alert:
        'Dogodila se greška. Molimo pokušajte ponovno ili prijavite grešku preko email-a.',
    },
    browser_not_supported: {
      title: 'FairVotes.LIVE',
      message: 'Vaš Internet preglednik nije podržan.',
    },
  },
}
