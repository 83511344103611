class Message {
  id = null

  message = ''

  seen = false

  archived = false

  event_id = null

  voter_id = null

  created_at = null

  updated_at = null

  constructor(options = {}) {
    Object.assign(this, options)
  }

  // let message = (new Message).fill({ message: "whatever" })
  fill(options) {
    return Object.assign(this, options)
  }
}

export default Message
