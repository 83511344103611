import Vue from 'vue'
import VueI18n from 'vue-i18n'
import hr from '@/lang/hr.js'
import en from '@/lang/en.js'
import sl from '@/lang/sl.js'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'hr', // set locale
  messages: {
    hr: hr,
    en: en,
    sl: sl,
  },
})
