import beforeunload from '@/helpers/beforeunload.js'

export default function (status_code, router, store) {
  if (status_code === 401) {
    localStorage.removeItem('token')

    store.setLoggedIn(false)

    beforeunload.disable()

    // Disconnect from pusher if connected.
    if (store.state.pusherInstance !== null) {
      store.state.pusherInstance.disconnect()
    }

    router.push({ name: 'Unauthorized' })
  }
}
