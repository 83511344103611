export default {
  enable: () => {
    window.addEventListener('beforeunload', handle_event)
  },

  disable: () => {
    window.removeEventListener('beforeunload', handle_event)
  },
}

function handle_event(event) {
  // Cancel the event as stated by the standard.
  event.preventDefault()

  // Chrome requires returnValue to be set.
  event.returnValue = ''
}
