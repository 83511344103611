import handle_errors from '@/api/handle_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'
import get_question from '@/api/get_question.js'

export default function (id, success, failure) {
  get_question(id, localStorage.getItem('token'))
    .then(handle_token_refresh)
    .then(handle_errors)
    .then(success)
    .catch(failure)
}
